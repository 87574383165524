import React from "react";
import Home from "../component/Home/Home";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import "../styles/global.css";

const App = () => {
  const pageData = {
    residentialRealtors: {
      title: "San Diego Residential Realtors",
      subtitle: "With Your Best Interests At Heart",
      description: (
        <p>
          This is about more than a deal—it’s about your family's happiness and building a great
          future. Whether you’re{" "}
          <a className="!text-blue-500" href="https://selbysellssd.com/buy-house-san-diego/">
            buying
          </a>
          ,{" "}
          <a className="!text-blue-500" href="https://selbysellssd.com/sell-your-san-diego-home/">
            selling
          </a>
          , or investing, our experienced residential realtors will connect you with the best
          opportunities in San Diego.{" "}
        </p>
      ),
    },
    findNextHome: {
      card1Title: "Find Your Next Home",
      card2Title: "What Is Your Home Worth?",
    },
    listings: {
      title: "Find Your Place in San Diego",
      description: (
        <p>
          From Allied Gardens to University Heights and every community in between, discover the
          latest San Diego properties here. Our listings are pulled directly from the MLS and
          updated in real-time, giving you immediate access to homes the minute they hit the market.
          Your home search begins here!
        </p>
      ),
    },
    meetFamily: {
      title: "Meet Our Family",
      subtitle:
        "We’re dedicated San Diego realtors who help families like yours throughout the homeownership journey—from buying to selling to investing. We’re real estate advisors who are in your corner from day one.",
    },
    cta: {
      description:
        "Connect with a top-rated team of San Diego realtors who listen, are readily available, and will work tirelessly for you.",
    },
  };

  return (
    <LayoutWrapper
      title="San Diego Realtors | Real Estate Agency | The Selby Team"
      desc="Find your next home or sell the one you own with San Diego realtors who have your best interests at heart. Make The Selby Team your residential realtor."
      headerLogo="../../images/Selby_logo.svg"
    >
      <Home pageData={pageData} />
    </LayoutWrapper>
  );
};

export default App;
